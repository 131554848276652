import { renderToString } from 'react-dom/server';

import React, {useEffect, useState, useRef} from 'react';
import { YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  isBrowser
} from "react-device-detect";
import axios from "../../services/axios";


export default()=>  {
  let defaultProps = {
    zoom: 12
  };
  let settings = {
    width: '50%',

    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };
  const [data, setData] = useState({});
  const [langInData, setLangInData] = useState({});
  const [type, setType] = useState({type:false});
  const [popupOpened, setPopupOpened] = useState(false);
    const togglePopup = () => {
        setPopupOpened(!popupOpened);
    };

useEffect(() => {
  axios().get('full-contacts')
    .then(({data}) => {
      setData(data);
    })
    .catch(error => console.error(error));
}, []);

  const center_in_map = {lat: 0, lng: 0};
  let markers = []
  for (let key in data) {
      center_in_map.lat +=  +data[key].latitude;
      center_in_map.lng +=  +data[key].longitude;
      markers.push(data[key])
  }

  const marksNumber = markers.length
  center_in_map.lat = center_in_map.lat / marksNumber
  center_in_map.lng = center_in_map.lng / marksNumber

  return (
      <div className="map" id ="map">
        <YMaps query={{
            apikey: '7c73b487-0c15-4147-b9a3-3fb7df5f7e4c',
            ns: "use-load-option",
            load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon",
        }}>
            <Map width='100%' 
            height='100%' 
            defaultState={{ center: [55.75139, 37.61099], zoom: 11 }}>
                {
                    markers.map((item) => {
                        const div = document.createElement('div');
                        div.setAttribute('id', 'map-temp-element')
                        console.log(item.location)
                        
                        const contentComponent = <div>
                            <h3 dangerouslySetInnerHTML={{__html: `<a href='${item.url}' class="laguna-text-color">`  + item.location + `</a>`}}></h3>
                            <div className=" " dangerouslySetInnerHTML={{__html: item.details.replace(/\n|(<l|l>)|(<m|m>)|(<t|t>)/gi, function(x) {
                            switch (x) {
                                case '\n':
                                return '</br>'
                                case '<l':
                                return ``
                                case 'l>':
                                    return ''
                                case '<m':
                                return `<a href="mailto:${item.details.substring(item.details.search('<m')+2,item.details.search('m>'))}" class="laguna-text-color">`
                                case 'm>':
                                return '</a>'
                                case '<t':
                                return `<a href="tel:${item.details.substring(item.details.search('<t')+2,item.details.indexOf('t>'))}" class="laguna-text-color">`
                                case 't>':
                                return '</a>'
                            default:
                                return x
                            }
                            })}}>
                            </div>
                        </div>

                        const markerHtml = renderToString(contentComponent)

                        const iconCaption = 'Лагуна, ' + item.location
                        return <Placemark geometry={[item.latitude, item.longitude]} properties={{
                            iconCaption,
                            balloonContentBody: markerHtml
                        }}
                        options={{ preset: 'islands#dotIcon',
                        iconColor: '#0077B5'}} />
                    })
                }
            </Map>
        </YMaps>
      </div>
  );
}
