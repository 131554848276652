import React, {useEffect, useState, Fragment} from 'react';
import SolariumMobileCollapse from "./solariumMobileCollapse";
import Collapsible from "react-collapsible";

export default(props) => {
  let {  tabsData, open } = props;
  return(
    !tabsData.zagar_blocks ? <div></div> :
      <Fragment>
        <div className="position">
          <hr className="home-hr"/>
        </div>
        <Collapsible className="menu-item" trigger={tabsData.name} open={open}>
          { tabsData.zagar_blocks.map((item, key) => (
            <SolariumMobileCollapse item={item.zagar_tabs_id == tabsData.id ? item : ''} />
          ))}
        </Collapsible>
      </Fragment>
  )
}