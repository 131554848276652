import React from 'react';

export default (props) => {
  return (
    <div className="coast-table">
      <div className="header">
        <div className="row">
          <div className="floatLeft"><hr/>Наименование услуг</div>
          <div className="floatRight"> Цена (руб.)</div>
        </div>
      </div>
      <div className="body">
        {props.list && props.list.map(item=> (
          <div className="row text">
            <div className="floatLeft" dangerouslySetInnerHTML={{__html: item.product || item.text}}></div>
            <div className="floatRight" dangerouslySetInnerHTML={{__html: item.price}}></div>
          </div>
        ))}
      </div>
    </div>
  )
}
