import React, {Fragment,} from 'react';
import ImagesWithTextAndProductCollapse from "../Helpers/imagesWithTextAndProductCollapse";
import Collapsible from "react-collapsible";

export default(props) => {
  let {  tabsData, open } = props;
  return(
    !tabsData.salon_blocks ? <div></div> :
      <Fragment>
        <div className="position">
          <hr className="home-hr"/>
        </div>
        <Collapsible className="menu-item" trigger={tabsData.name} open={open}>
          { tabsData.salon_blocks.map((item, key) => (
              <ImagesWithTextAndProductCollapse key={item.salons_tabs_id} item={item.salons_tabs_id == tabsData.id ? item : ''} />
            ))}
        </Collapsible>
      </Fragment>
  )
}