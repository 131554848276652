import React, { Fragment } from 'react';
import Router from './components/Router';

export default () => {
  return (
    <Fragment>
      <Router  />
    </Fragment>
  );
};
