import React, {useEffect, useState, Fragment} from 'react';
import axios from "../services/axios";
import Image from '../images/Laguna logo-01.svg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FooterImages from './Main/FooterImages';
import {
  isBrowser
} from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faVk,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';
import {Link} from "react-router-dom";
import {ROOT_API} from "../constants";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Close from '../images/close.svg';


export default (props)=>{
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [open, setOpen] = React.useState(false);
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }


  const [data, setData] = useState({});
  const [menus, setMenus] = useState({});
  const [socialNetworks, setSocialNetworks] = useState({});
  let [images, setImages] = useState([]);
  useEffect(() => {
    axios().get('full-contacts')
      .then(({data}) => {
        setData(data);
      })
      .catch(error => console.error(error));
    axios().get('menus')
      .then(({data}) => {
        setMenus(data);
      })
      .catch(error => console.error(error));
    axios().get('social-networks')
      .then(({data}) => {
        setSocialNetworks(data);
      })
      .catch(error => console.error(error));
    axios().get('footer-images')
      .then(({data}) => setImages(data))
      .catch(error => console.error(error));
  }, []);
  return (
      !data.length ? <div></div> :
        isBrowser ?
     <Fragment>
       <div className="footer-page cosmetics-inner">
         <FooterImages images={images}/>
       </div>
       {isBrowser ? 
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={Close} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
          : <div></div>}

       <div className="footer">
         <div className="footer_location">
           <div className='location_max_width'>
             {data.map(item =>
               <div className="location-block" key={item.id}>
                 <h3>{item.location}</h3>
                 <div className="location" dangerouslySetInnerHTML={{__html: item.details.replace(/(<l|l>)|(<m|m>)|(<t|t>)/gi, function(x) {
                    switch (x) {
                      case '<l':
                      return ``
                        break;
                      case 'l>':
                        return ''
                        break;
                      case '<m':
                      return `<a href="mailto:${item.details.substring(item.details.search('<m')+2,item.details.search('m>'))}" class="laguna-text-color">`
                        break;
                      case 'm>':
                        return '</a>'
                        break;
                      case '<t':
                      return `<a href="tel:${item.details.substring(item.details.search('<t')+2,item.details.indexOf('t>'))}" class="laguna-text-color">`
                        break;
                      case 't>':
                        return '</a>'
                        break;

                    }
                   return x;
                  }) }}>
                    
                 </div>
               </div>
             )}
           </div>
           <div className="button-div">
             <button className='button-black' onClick={handleClickOpen}>Онлайн-запись</button>
           </div>
         </div>
         {!menus.length ? <div></div>:
           <div className="footer-last-part">
             {menus.map(item =>
               <div className="footer-menu-block" key={item.id}>
                 <div className="menu-title">{item.name}</div>
                 {item.submenus.map(el => {if ( el.design_type.name !== 'BeautySalon') {
                       return ( <div key={el.id} className="menu-item">
                        <Link to={`/service/${el.id}`}>
                          { el.name }
                        </Link>
                        </div>)
                      }  else {
                         return el.submenu_header.salon.salon_tabs.map(tabs => 
                        <div key={tabs.id} className="menu-item">
                        <Link to={`/service/${el.id}/tab/${tabs.id}`}>
                          { tabs.name }
                        </Link>
                        </div>
                        )
                      }
                    }
                      )}
               </div>
             )}
           </div>
         }
         <div className='footer-contact-part'>
           <div className='flex-end footer-logo-img-size' >
           <img src={Image} alt="" className=""/>

           {/* <object type="image/svg+xml" data={Image} className="footer-image-svg">
            </object> */}
           </div>
      

           <div className='contacts-icons'>
             <div className="icons-text">мы в соц сетях</div>
             {socialNetworks.data && socialNetworks.data.map(item =>
              <div className="icons">
                <a href={item.url}>
                  <img src={ROOT_API+'/uploads/'+item.image} alt="" className='icons-image'/>
               </a>
              </div>
             )}
           </div>
         </div>
         <div className="position copyright">
           <hr className="home-hr"/>
           <div className="home-hr-title footer-left">Laguna &copy; 2006-{(new Date().getFullYear())}</div>
            <div className="home-hr-title">·</div>
            <div className="home-hr-title footer-right">Website by <a href="http://digiter.am/" className="active-tab">Digiter</a></div>
         </div>
       </div>
     </Fragment>
          :
          <Fragment>
            <div className="footer-mobile">
            {!isBrowser ? 
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={Close} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
          : <div></div>}
              <div className="button-div">
                <button className='button-black' onClick={handleClickOpen}>Онлайн-запись</button>
              </div>
              <img src={Image} alt="" className="footer-img-logo"/>
              <div className='contacts-icons'>
                <hr className="floatLeft"/>
                {socialNetworks.data && socialNetworks.data.map(item =>
                  <div className="icon-width floatLeft">
                    <a href={item.url}>
                      <img src={ROOT_API+'/uploads/'+item.image} alt=""/>
                    </a>
                  </div>
                )}
                <hr className="floatLeft"/>
              </div>
              <div className="footer-text">мы в соц сетях</div>
              {!menus.length ? <div></div>:
                <div className="footer-last-part">
                  {menus.map(item =>
                    <div className="footer-menu-block" key={item.id}>
                      <h3 className="menu-title">{item.name}</h3>
                      {item.submenus.map(el => {if ( el.design_type.name !== 'BeautySalon') {
                       return ( <div key={el.id} className="menu-item">
                        <Link to={`/service/${el.id}`}>
                          { el.name }
                        </Link>
                        </div>)
                      }  else {
                         return el.submenu_header.salon.salon_tabs.map(tabs => 
                        <div key={tabs.id} className="menu-item">
                        <Link to={`/service/${el.id}/tab/${tabs.id}`}>
                          { tabs.name }
                        </Link>
                        </div>
                        )
                      }
                    }
                      )}
                    </div>
                  )}
                </div>
              }
              <div className="position copyright-mobile">

                <div className="home-hr-title footer-left"><hr className="home-hr"/>Laguna &copy; 2006-{(new Date().getFullYear())}</div>
                <div className="home-hr-title footer-dot-mobile">·</div>
              </div>
            </div>
          </Fragment>
  )
}

