import React, { useEffect, useState } from 'react';
import '../../styles/index.scss';
import axios from '../../services/axios';
import BeautySalon from './BeautySalon';
import Cosmetics from './cosmetics';
import GiftCard from './GiftCard';
import LpgMassage from './LpgMassage';
import Solarium from './Solarium';
import Tanning from './Tanning';

function getComponent({ designName, data, service_id }) {
  switch(designName) {
    case 'BeautySalon':
      return <BeautySalon data={data} service_id={service_id} />;
    case 'Cosmetics':
      return <Cosmetics data={data} />;
    case 'GiftCard':
      return <GiftCard data={data} />;
    case 'LpgMassage':
      return <LpgMassage data={data} />;
    case 'Solarium':
      return <Solarium data={data} service_id={service_id}/>;
    case 'Tanning':
      return <Tanning data={data} />;
  }
}

export default (props) => {
  const [component, setComponent] = useState(null);
  const { params: { id, tab } } = props.match;
  useEffect(() => {
    axios().get(`submenu-page/${id}`)
      .then(response => {
        const { designName } = response.data;
        setComponent({ designName, data: response.data, service_id: id });
      })
      .catch(error => console.error(error));
  }, [props.match]);
  return (!component ? <div></div> : getComponent(component));
}