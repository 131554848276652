import React, {useEffect, useState} from 'react';
import ContentHeaderPart from '../Helpers/contentHeaderPart';
import TitleAndDescription from '../Helpers/titleAndDescription';
import Title from '../Helpers/title';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import axios from "../../services/axios";
import MetaData from "../Helpers/meta_data";

export default ({data})=> {
  const [headerData, setHeaderData] = useState({});
  useEffect(() => {
    axios().get(`/submenu-headers/${data.submenu_headers_id}`)
      .then(response => setHeaderData(response.data))
      .catch(error => console.error(error));
  }, []);
  let images = [];
  for (let key in headerData) {
    if (key.indexOf('image') > -1) {
      images[key] = headerData[key];
    }
  }
  if(data) {
    window.scrollTo(0, 0);
  }
  {data.meta_data.og_imagae =  !data.meta_data.og_image ? images.image_one  : data.meta_data.og_image}

  return(
    !data.center ? <div></div>:
    <div className="solarium container gift-card">
      <MetaData data={data.meta_data}/>
      <ContentHeaderPart title={headerData.title} text={headerData.text} images={images}/>
      <div className="four-title-and-description small-title">
        {
          data.header.map(item=>
            <TitleAndDescription title={item.title} description={item.text}/>
          )
        }
      </div>
      <div className="four-title-and-description gift-card-context">
        {
          data.center.map(item=>
            <TitleAndDescription title={item.title} description={item.text}/>
          )
        }
      </div>
    </div>
  )
}
