import React, {useState, useEffect, Fragment} from 'react';
import TitleAndDescription from '../Helpers/titleAndDescription';
import axios from '../../services/axios';
import {Carousel} from 'react-responsive-carousel';
import {ROOT_API} from '../../constants';
import MetaData from "../Helpers/meta_data";
import {Link} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'

export default (props) => {

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1
  };
  AOS.init({
    duration : 800
  })
  const [data, setData] = useState({});
  useEffect(() => {
    axios().get(`${props.match.url}`)
      .then(response => setData(response.data))
      .catch(error => console.log('cosmeticspage', error));
  }, [props.match.url]);
  if(data.cosmeticsData && data.cosmeticsData.meta_datum) {
    data.cosmeticsData.meta_datum.og_image =  !data.cosmeticsData.meta_datum.og_image ?
                data.cosmeticsData.image : data.cosmeticsData.meta_datum.og_image;
  }
  if(data) {
    window.scrollTo(0, 0);
  }
  return (
    !data.cosmeticsData ? <div></div> :
      <div className="cosmetics-inner">
        <MetaData data={data.cosmeticsData.meta_datum}/>
        <Fragment>
            <div className="cosmetics-inner-header overlay zoom view" data-aos="fade-up">
              <img src={ROOT_API + '/uploads/' + data.cosmeticsData.image} alt="" className="cosmetics-inner-img"/>
              <div className="header-part">
                <div className="interested-questions">
                  <h2 className="interested-questions__question cosmetics-row" dangerouslySetInnerHTML={{__html: data.cosmeticsData.title}}>
                  </h2>
                  <div className="interested-questions__answer" dangerouslySetInnerHTML={{__html: data.cosmeticsData.text}}>
                  </div>
                </div>
              </div>
            </div>

            <Carousel {...settings} className="four-image-content"
                      swipeable={true} showThumbs={false}
                      emulateTouch={true}
                      showIndicators={false}
                      showStatus={false}
                      showArrows={true} infiniteLoop={false}>
                { data.relatedProducts.map(item =>
                  <Fragment key={item.id}>
                    <Link to={`/cosmetic-product/${item.id}`} onClick={()=>{window.location.href = `/cosmetic-product/${item.id}`}}>
                    <div className="overlay zoom view" data-aos="fade-up">
                      <img src={ROOT_API + '/uploads/' + item.image} alt=""/>
                    </div>
                    
                    </Link>
                    <div className="carousel">
                      <div className="interested-questions" key={item.id}>
                        <h3 className="interested-questions__question cosmetics-row" dangerouslySetInnerHTML={{__html: item.title}}></h3>
                        <div className="interested-questions__answer" dangerouslySetInnerHTML={{__html: item.text.substring(0,85)}}></div>
                      </div>
                    </div>
                    <div className="position cosmetics-see-details top10">
                      <hr className="home-hr"/>
                      <Link className='button-black' to={`/cosmetic-product/${item.id}`} onClick={()=>{window.location.href = `/cosmetic-product/${item.id}`}}>
                        <div className="floatLeft">Подробнее</div>
                      </Link>
                    </div>
                  </Fragment>
                )}
            </Carousel>
          </Fragment>
      </div>
  );
}

