import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ImageWithTextAndProductMobile from "./imagesWithTextAndProductMobile";
import React, {Fragment, useState} from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  isBrowser,
} from "react-device-detect";

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

function CenteredGrid(props) {
  const { data } = props;
  const tab = window.location.pathname.split('tab/')[1] || Object.values(data.salon_tabs)[0].id;
  const [tabContent, setTabContent] = useState(tab);

  return (
    !data.salon_tabs ? <div></div>:
    <Fragment>
      {Object.values(data.salon_tabs).map(items => {
        if (tabContent == items.id) {
          return <ImageWithTextAndProductMobile tabsData={items} open={true}/>
        } else {
          return <ImageWithTextAndProductMobile tabsData={items} open={false}/>
        }
      }
      )}
    </Fragment>
  )
}
CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CenteredGrid);