import React, {useEffect, useState, Fragment} from 'react';
import {withStyles} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { shadows } from '@material-ui/system';

import ContactImage from '../../images/contact.png';
import axios from "../../services/axios";
import MetaData from "../Helpers/meta_data";

const useStyles = theme => ({
  root: {
    width: '100%',
    marginTop: '50px',
    overflowX: 'auto',
    boxShadow: 'none',
  },
  table: {
    minWidth: 650,
    shadows: ["none"],
  },
});

function createData(name, calories, fat, carbs, protein) {
  return {name, calories, fat, carbs, protein};
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const Contact = (props) => {

  const {classes} = props;
  const [services, setServices] = useState({});
  const [metaData, setMetaData] = useState({});
  const [fullContacts, setFullContacts] = useState({});
  useEffect(() => {
    axios().get('contacts-meta')
      .then(({data}) => {setMetaData(data)})
      .catch(error => console.error(error));
    axios().get('contacts-service')
      .then(({data}) => {
        setServices(data);
      })
      .catch(error => console.error(error));
    axios().get('full-contacts')
      .then(({data}) => {
        setFullContacts(data);
      })
      .catch(error => console.error(error));
  }, []);

  if(services) {
    window.scrollTo(0, 0);
  }
  return (
    <div className="contact-table-out">
      {!metaData.meta ? <div></div> :
      <MetaData data={metaData.meta}/>
      }
      <div className="contact-table" >
        <Paper className={classes.root} style={{boxShadow: "none !important"}} >
          <Table >
            <TableHead >
              <TableRow className="table-row" >
                <TableCell>Местонахождение</TableCell>
                <TableCell align="right">Детали</TableCell>
                {!services.data ? <TableCell></TableCell> :
                  services.data.map(item =>
                    <TableCell align="right">{item.name}</TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!fullContacts.length ? <div></div> :
                fullContacts.map(row => {
                    row.details = (row.details.indexOf("<a href=\"https://tulsky.ru/\" target=\"_blank\" class=\"laguna-grey\">tulsky.ru</a>") >= 0 ) ? row.details : row.details.replace('tulsky.ru', '<a href="https://tulsky.ru/" target="_blank" class="laguna-grey">tulsky.ru</a>')
                    return (
                <TableRow className="table-row" key={row.name}>
                <TableCell component="th" scope="row">
                {row.location}
                </TableCell>
                <TableCell align="right" dangerouslySetInnerHTML={{__html: row.details.replace(/(<l|l>)|(<m|m>)|(<t|t>)/gi, function(x) {
                  switch (x) {
                    case '<l':
                      return `<a href="${row.url}" class="laguna-grey">`
                      break;
                    case 'l>':
                      return '</a>'
                      break;
                    case '<m':
                      return `<a href="mailto:${row.details.substring(row.details.search('<m')+2,row.details.search('m>'))}" class="laguna-grey">`
                      break;
                    case 'm>':
                      return '</a>'
                      break;
                    case '<t':
                      return `<a href="tel:${row.details.substring(row.details.search('<t')+2,row.details.search('t>'))}" class="laguna-grey">`
                      break;
                    case 't>':
                      return '</a>'
                      break;

                  }
                  return x;
                })}}></TableCell>
                {!services.data ? <div></div> :
                    !row.contacts_services.length ?
                        services.data.map(item =>
                            <TableCell align="right">-</TableCell>
                        )
                        :
                        services.data.map((el, index) => {
                              return row.contacts_services.find(function (element) {
                                    return element.id == el.id
                                  }
                              ) ?
                                  <TableCell align="right"><img src={ContactImage} alt=""/></TableCell> :
                                  <TableCell align="right">____</TableCell>
                            }
                        )
                }
                </TableRow>
                )}
                    )}
            </TableBody>
          </Table>
        </Paper>
        <div className="contact-text-center">
        Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская.
        </div>
      </div>
      <div className="contact-iframe">
        <iframe id="shedulWidget" src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
      </div>
    </div>

  );
}
export default withStyles(useStyles)(Contact)