import React, {useState, useEffect, Fragment} from 'react';
import ContentHeaderPart from '../Helpers/contentHeaderPart';
import CoastTable from '../Helpers/coastTable';
import TitleAndDescription from '../Helpers/titleAndDescription';
import axios from '../../services/axios';
import {ROOT_API} from "../../constants";
import {isBrowser} from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Carousel} from "react-responsive-carousel";
import {Link} from "react-router-dom";
import MetaData from "../Helpers/meta_data";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloseSvg from '../../images/close.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'

export default ({data})=> {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  AOS.init({
    duration : 800
  })
  const [headerData, setHeaderData] = useState({});
  const [visible, setVisible] = useState({isSecondOpen: true, isFirstOpen: true});

  const [open, setOpenModal] = React.useState(false);
  function handleClickOpen() {
    setOpenModal(true);
  }

  function handleClose() {
    setOpenModal(false);
  }
  
  useEffect(()=> {
    axios().get(`/submenu-headers/${data.submenu_headers_id}`)
      .then(response => setHeaderData(response.data))
      .catch(error => console.log('lpg', error));
  }, [data.submenu_headers_id]);
  let images = [];
  let dataImages = [];
  for (let key in headerData) {
    if (key.indexOf('image') > -1) {
      images[key] = headerData[key];
    }
  }
  for (let key in data) {
    if (key.indexOf('image') > -1) {
      dataImages[key] = data[key];
    }
  }

  if(data) {
    window.scrollTo(0, 0);
  }
  data.meta_data.og_imagae = !data.meta_data.og_image ? images.image_one : data.meta_data.og_image;
  return(
    !data ? <div></div> :
    <div className="solarium container">
      <MetaData data={data.meta_data}/>
      <ContentHeaderPart title={headerData.title} text={headerData.text} images={images}/>
      {isBrowser ? 
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={CloseSvg} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
          : <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={CloseSvg} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>}
      {      isBrowser ?
      <div className="video-and-text-content">
        <div  className={`text-content floatLeft ${visible.isFirstOpen ? '' : ''}`}>
          <TitleAndDescription title={data.title} description={(visible.isFirstOpen ? data.text.substring(0,375) : data.text)}/>
          {data.text.length > 300 ?
            <div className="position see-details">
              <div onClick={()=>{
                setVisible({isFirstOpen: !visible.isFirstOpen, isSecondOpen: visible.isSecondOpen})
                if(!visible.isFirstOpen) window.scrollTo(0,0);
              }} className="floatRight">
                {visible.isFirstOpen ? 'Читать далее' : 'Свернуть'}</div>
              <hr className="home-hr top10 floatRight"/>
            </div>
            : <div></div>
          }
          <CoastTable list={data.product_list} className="tanning-coast-table"/>
          <div className="button-position">
              <button className="tips-and-questions__button button-black" onClick={handleClickOpen}>
                Онлайн-запись
              </button>
          </div>
        </div>
          <div className="menu-image-content" data-aos="fade-up">
            <div className="menu-image-item overlay zoom view"><img src={ROOT_API + '/uploads/' + data.image_one} alt=""/></div>
            <div className="menu-image-item overlay zoom view"><img src={ROOT_API + '/uploads/' + data.image_two} alt=""/></div>
          </div>
      </div>
          :
                  <div className="video-and-text-content">
                    <Carousel {...settings} className="four-image-content"
                              emulateTouch={true}
                              swipeable={true} showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={false}>
                      {dataImages.map(item =>
                        <img src={ROOT_API + '/uploads/' + item} alt=""/>
                      )}
                    </Carousel>
                    <div className={`${visible.isSecondOpen ? 'hidden_questions' : ''}`}>
                      <div className="text" dangerouslySetInnerHTML={{__html: data.text}}>
                      </div>
                      {data.text.length > 300 ?
                        <div className="position see-details">
                          <div onClick={()=>{
                            setVisible({isFirstOpen: visible.isFirstOpen, isSecondOpen: !visible.isSecondOpen})
                            if(!visible.isSecondOpen) window.scrollTo(0,0);
                          }} className="floatRight">
                            {visible.isSecondOpen ? 'Читать далее' : 'Свернуть'}</div>
                          <hr className="home-hr top10 floatRight"/>
                        </div>
                        : <div></div>
                      }
                    </div>
                    <div className="button-div position">
                      <button className='button-black' onClick={handleClickOpen}>Онлайн-запись</button>
                    </div>
                  </div>
      }
    </div>
  )
}
