import React from 'react';
import MetaTags from 'react-meta-tags';
import {ROOT_API} from "../../constants";

export default ({data}) =>{
    return (
      data === undefined || data === null ? <div></div> :
        <MetaTags>
          <meta name="description" content={data.description}/>
          <meta property="og:title" content={data.title} />
          <meta property="og:image" content={ROOT_API+'/uploads/'+data.og_image} />
        </MetaTags>
    )
}