import React, { useEffect, useState } from 'react';
import {withRouter } from 'react-router';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import SubMenuCollapse from './SubmenuCollapse';
import { Link } from 'react-router-dom';
import axios from '../../services/axios';
import Image from '../../images/laguna-logo.svg';
import Hamburger from '../../images/hamburger-icon.png';
import Close from '../../images/close-icon.png';
import {
  isBrowser,
} from "react-device-detect";
import Drawer from '@material-ui/core/Drawer';
import 'typeface-roboto';
import {ROOT_API} from "../../constants";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseSvg from '../../images/close.svg';



function getStructure(menu) {
  const menuNames = ['abonement', 'zagar', 'autozagar', 'salon'];
  return {
    ...menu,
    submenus: menu.submenus.map(
      (data) => {
        const { submenu_header, cosmetics_page } = data;
        if (cosmetics_page) {
          return  {
            ...data,
            api: `cosmetics_page/${cosmetics_page.id}`,
          };
        }
        const existingName = menuNames.find(name => submenu_header[name]);
        return {
          ...data,
          api: `${existingName}s/${submenu_header[existingName].submenu_headers_id}`
        };
      }
    )
  };
}

export default withRouter ((props) => {
  const [menus, setMenuData] = useState([]);
  const [socialNetworks, setSocialNetworks] = useState({});
  const [documentTitle, setDocumentTitle] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [activeMenu, setActiveMenu] = useState({id:''});
  const [activeSubMenu, setActiveSubMenu] = useState({id:''});
  const [isCollapseOpen, setIsCollapseOpen] = useState({isOpen:false});

  useEffect(()=> {
    axios().get('header')
      .then(({data}) => {
        setDocumentTitle(data);
      })
      .catch(error => console.error(error));
    axios().get('social-networks')
      .then(({data}) => {
        setSocialNetworks(data);
      })
      .catch(error => console.error(error));
    axios().get('menus')
      .then(response => setMenuData(response.data))
      .catch(error => console.error(error));
  }, []);

  if(menus.length) {
    document.title = documentTitle.title;
  }

  const [open, setOpenModal] = React.useState(false);
  function handleClickOpen() {
    setOpenModal(true);
  }

  function handleClose() {
    setOpenModal(false);
  }
  const path = props.location.pathname
  return (!menus.length ? <div></div> : isBrowser ?
  <MuiThemeProvider>
      <div className="header">
      {isBrowser ? 
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={CloseSvg} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
          : <div></div>}
        <div className="container">
          <div className="logo-content">
            <Link to={'/home'}><img src={Image} alt="../images/logo.png" className="logo"/></Link>
          </div>
          <ul className="menu-items-content">
            <li className={`menu-item ${activeMenu.id === 'about' ? 'active-tab' : ''}`}
                onClick={()=>setActiveMenu({id: 'about'})}
                onMouseEnter={() => setContent(null)}>
              <Link to={'/about'}>О НАС</Link>
            </li>
            {menus.map(item =>
              item.submenus.length > 1 ?  <li
                key={item.id}
                className={`menu-item ${activeMenu.id === item.id ? 'active-tab' : ''}`}
                onClick={()=>setActiveMenu({id: item.id})}
                onMouseEnter={() => {
                  if(!(content && content.id === item.id)) {
                    setContent(getStructure(item))
                  }
                  setIsCollapseOpen({isOpen: true});
                }}
                onMouseLeave={()=>{setIsCollapseOpen({isOpen: false});}}
                >
                { item.name }
                {content !==null && isCollapseOpen.isOpen ?
                <div className="hover-icon"></div>
                : <div></div>}
              </li>
              :
              <li
                key={item.id}
                onClick={()=>setActiveMenu({id: item.id})}
                className={`menu-item ${activeMenu.id === item.id ? 'active-tab' : ''}`}
                onMouseEnter={() =>setContent(null)}
              >
                <Link to={`/service/${item.submenus[0].id}`}> { item.name }</Link>
              </li>
            )}
            <li onClick={()=>setActiveMenu({id: 'contact'})}
                className={`menu-item ${activeMenu.id === 'contact' ? 'active-tab' : ''}`}
                onMouseEnter={() => setContent(null)}>
              <Link to={'/contact'}>КОНТАКТЫ</Link>
            </li>
            <div className="menu-item">
              <button className='button-black' onClick={handleClickOpen}>
                Онлайн-запись
              </button>
            </div>
          </ul>
          {
            content !==null && isCollapseOpen.isOpen ?
                  <div className="for-hover subMenu-coll"
                       onMouseEnter={()=>setIsCollapseOpen({isOpen:true})}
                       onMouseLeave={()=>{setIsCollapseOpen({isOpen:false})}}>
                    <SubMenuCollapse
                      content={content}
                      setContent={setContent}
                      activeMenu = {activeMenu}
                      setActiveMenu = {setActiveMenu}
                      // setIsCollapseOpen = {setIsCollapseOpen}
                    />
                  </div>
             : <div></div>
          }
        </div>
      </div>
    </MuiThemeProvider>
        :
        <div className="header">
          <div className="logo-content">
            <Link to={'/home'} onClick={() => setActiveSubMenu('home')}><img src={Image} alt="../images/logo.png" className="logo logo-mobile"/></Link>
          </div>
          <MuiThemeProvider >
            <div className='mobile-header-button'
                 onClick={()=>setOpen(!isOpen)}
            >
              <img src={Hamburger}/>
            </div>
          </MuiThemeProvider>
          <Drawer className="mobile-drawer" side="right" drawerposition={'right'} open={isOpen}>
            <div className = "close-icon" onClick={()=>setOpen(!isOpen)}>
              <img src={Close} alt=""/>
            </div>
            <div className={`submenu-items`} >
              <div className="menu-names" onClick={()=>{
                 setActiveMenu({id: 'about'});
                  setActiveSubMenu('about');
                   setContent(null);
                    setOpen(!isOpen);
                     setActiveMenu({id: 'about'});
                }
                     } ><Link to={`/about`} className={` ${ path.match(/\/\w+\/\d+|\/\w+\/|\/\w+/gi) !== null &&  path.match(/\/\w+\/\d+|\/\w+\/|\/\w+/gi)[0].search('about') !== -1 ? 'cred' : ''}`}
                 >О НАС</Link></div>
            </div>
            {menus.map(items =>  {
              if(items.submenus.length === 1) {
                let active =""
                const regex = new RegExp('\/service\/' +items.submenus[0].id +'\/|\/service\/' + items.submenus[0].id + '$' ,'g')
                if (path.match(regex)) {
                    active = "cred"
                }
                return (
                <div
                  key={items.id}
                  className="submenu-items"
                  onClick={() => {setContent(items.id); setOpen(!isOpen); setActiveSubMenu(items.submenus[0].id) }}>
                  <div className="menu-names">
                    <Link to={`/service/${items.submenus[0].id}`} className={` ${active}`}>
                      { items.name }
                    </Link>
                  </div>
                  <div>
                    {items.submenus.map((item)=> {
                      let active =""
                      const regex = new RegExp('\/service\/' +item.id +'\/|\/service\/' + item.id + '$' ,'g')
                      if ( path.match(regex)) {
                          active = "cred"
                      }
                      if (item.design_type.name !== 'BeautySalon') {
                        return (
                          <div className={`submenu-items `} key={item.id} onClick={()=>{setOpen(!isOpen); setActiveSubMenu(item.id)} }>
                            <Link to={`/service/${item.id}`} className={` ${active}`}>
                            { item.name }
                            </Link>
                          </div>
                        )
                      }
                    }
    
                    )}
                  </div>
                </div>
                )
              } else {
                return (
                  <div
                  key={items.id}
                  className="submenu-items"
                  onClick={() => {setContent(items.id); setOpen(!isOpen) }}>
                  <div className="menu-names">
                    { items.name }
                  </div>
                  <div>
                    {items.submenus.map((item)=> {
                      let active =""
                      const regex = new RegExp('\/service\/' +item.id +'\/|\/service\/' + item.id + '$' ,'g')
                      if ( path.match(regex)) {
                          active = "cred"
                      }
                      if (item.design_type.name !== 'BeautySalon') {
                        return (
                          <div className={`submenu-items`} key={item.id} onClick={()=>{setOpen(!isOpen); setActiveSubMenu(item.id)} }>
                            <Link to={`/service/${item.id}`} className={`${active}`}>
                            { item.name }
                            </Link>
                          </div>
                        )
                      }
                    }
    
                    )}
                  </div>
                </div>
                )
              }

            }
            )}
            <div className="submenu-items" >
              <div className="menu-names" onClick={()=>{setActiveMenu({id: 'about'});setActiveSubMenu('contact'); setContent(null); setOpen(!isOpen);} }>
                <Link to={`/contact`} className={` ${ (path.match(/\/\w+\/\d+|\/\w+\/|\/\w+/gi)!== null && path.match(/\/\w+\/\d+|\/\w+\/|\/\w+/gi)[0].search('contact') !== -1) ? 'cred' : ''}`}>
                КОНТАКТЫ
                </Link>
            </div>
            </div>
            <div className="position cosmetics-see-details">
              <hr className="home-hr"/>
              <Link to={`/contact`}>
                <div className="floatLeft" onClick={() => setOpen(!isOpen) }>Онлайн-запись</div>
              </Link>
            </div>
            <div className="header-mobile-social">
              <div className='contacts-icons'>
                {socialNetworks.data && socialNetworks.data.map(item =>
                  <div className="icon-width floatLeft">
                    <a href={item.url}>
                      <img src={ROOT_API+'/uploads/'+item.image} alt=""/>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </Drawer>
        </div>
  );
})