import React, {useState, useEffect,Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import image from '../../images/logo.png';
import {ROOT_API} from '../../constants/index';
import axios from '../../services/axios';
import {
  isBrowser
} from "react-device-detect";
import {Carousel} from "react-responsive-carousel";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// import ReactImageZoom from 'react-image-zoom';
// import 'mdbreact/dist/css/mdb.css';
// import {  MDBView } from "mdbreact";
import AOS from 'aos';
import 'aos/dist/aos.css'



const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
  gridListHeigh: {
    width: '100%',
    height: 'auto', //1640
    float: 'left',
  },
  gridListContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  gridList: {
    transform: 'translateZ(0)',
    alignItems: 'stretch',
    overflow: 'hidden',

    '& img': {
      width: '100%',
      height: '100%',
      // padding: 10,
      paddingLeft: 0,
      paddingTop: 0,
    }
  },
  gridListHover: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
  },
  gridListSmall: {
    transform: 'translateZ(0)',
    // flexFlow: 'column',
    justifyContent: 'left',
    alignItems: 'stretch',
    overflow: 'hidden',
    padding: 20,
    paddingRight: 0,
    '& img': {
      flexShrink: 0,
      height: '50%',
      verticalAlign: 'middle',
      width: '100%'
    },
    '& div img': {
      flexShrink: 0,
      height: '100%',
      verticalAlign: 'middle',
      content: 'words',
    }
  }
});


function ImageGridList(props) {
  const {classes} = props;
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [activeType, setActiveType] = useState({id: Object.values(props.data)[0].hover_text});
  const [menus, setMenu] = useState({});
  const [visible, setVisible] = useState({});
  useEffect(() => {
    axios().get('menus')
      .then(response => setMenu(response.data))
      .catch(error => console.log('Home', error));
  }, []);
  let arr1=[];
  let arr = [];
  let array1 =[];
  let array2 =[];
  arr = Object.keys(props.data).slice(0, 3);
  arr1 = Object.keys(props.data).slice(3, 6);
    arr.map(item => array1.push(props.data[item]));
    arr1.map(item => array2.push(props.data[item]));
  return (
    isBrowser ?
      <div className={classes.root}>
        <Grid cellheight={160} className={classes.gridListHeigh} cols={3}>
          <div className={classes.gridListContainer} style={{height: '65%'}}>
          
            <div className={`grid-list-item ${classes.gridList} overlay zoom view`} style={{width: '71.4%', paddingLeft: 0}} >
            <Link to={`/service/${props.data.image_one.submenu_id}/${props.data.image_one.submenu_tab_id ? 'tab/' + props.data.image_one.submenu_tab_id : '' }`}>
              <div className='overlay zoom view' style={{height: '100%'}} data-aos="fade-up" data-aos-duration="2000">
                <img
                  src = { ROOT_API + '/uploads/' + props.data.image_one.image_url}
                  className=" grid-image"
                  alt={props.data.image_one.image_url}
                />
              </div>
              </Link>
              <div className={`${classes.gridListHover} grid-images-text overlay view`}>
                <div className="grid-images-text__desc">{props.data.image_one.hover_text}</div>
                <div>
                  <button className="homepage-grid-images-button">
                    <Link className='button-black' to={`/service/${props.data.image_one.submenu_id}/${props.data.image_one.submenu_tab_id ? 'tab/' + props.data.image_one.submenu_tab_id : '' }`}>ПОДРОБНЕЕ</Link>
                  </button>
                </div>
              </div>
            </div>

            <div className={classes.gridListSmall} style={{width: '35.9%', height: '100%', paddingTop: 0, paddingLeft:0}}>
              <div className={`grid-list-item home-grid-second-image`} style={{paddingRight: 0}}>
              <Link to={`/service/${props.data.image_two.submenu_id}/${props.data.image_two.submenu_tab_id ? 'tab/' + props.data.image_two.submenu_tab_id : '' }`}>
              <div className='overlay zoom view' style={{height: '100%'}} data-aos="fade-up" data-aos-duration="1500">
                <img
                  src = { ROOT_API + '/uploads/' + props.data.image_two.image_url}
                  className=" grid-image"
                  alt={props.data.image_one.image_url}
                  onMouseEnter={() => setVisible(props.data.image_two.id)}
                />
              </div>
              </Link>
                <div className={`grid-images-text ${classes.gridListHover}`} style={{top:'24%'}}>
                  <div className="grid-images-text__desc">{props.data.image_two.hover_text}</div>
                  <button className="homepage-grid-images-button">
                    <Link className='button-black' to={`/service/${props.data.image_two.submenu_id}/${props.data.image_two.submenu_tab_id ? 'tab/' + props.data.image_two.submenu_tab_id : '' }`}>ПОДРОБНЕЕ</Link>
                  </button>
                </div>
              </div>
              <div className={`grid-list-item second-grid-img `} style={{ paddingRight: 0}}>
              <Link to={`/service/${props.data.image_three.submenu_id}/${props.data.image_three.submenu_tab_id ? 'tab/' + props.data.image_three.submenu_tab_id : '' }`}>
                <div className='overlay zoom view' style={{height: '100%'}} data-aos="fade-up">
                  <img
                    src = { ROOT_API + '/uploads/' + props.data.image_three.image_url}
                    className=" grid-image"
                    alt={props.data.image_three.image_url}
                    onMouseEnter={() => setVisible(props.data.image_three.id)}
                  />
                </div> 
                </Link>
                <div className={`grid-images-text ${classes.gridListHover}`} style={{top: '73%'}}>
                  <div className="grid-images-text__desc">{props.data.image_three.hover_text}</div>
                  <button className="homepage-grid-images-button">
                    <Link className='button-black' to={`/service/${props.data.image_three.submenu_id}/${props.data.image_three.submenu_tab_id ? 'tab/' + props.data.image_three.submenu_tab_id : '' }`}>ПОДРОБНЕЕ</Link>
                  </button>
                </div>
              </div>
            </div>


          </div>
          <div  className={classes.gridListContainer} style={{height: '32.5%'}}>
            <div className={`grid-list-item ${classes.gridList}`} style={{width: '33%', paddingLeft: 0}}>
              {/* <img src={ROOT_API + '/uploads/' + props.data.image_four.image_url} className="grid-image"
                   alt={props.data.image_four.image_url}/> */}
                   <Link to={`/service/${props.data.image_four.submenu_id}/${props.data.image_four.submenu_tab_id ? 'tab/' + props.data.image_four.submenu_tab_id : '' }`}>
                <div className='overlay zoom view' data-aos="fade-up">
                  <img
                    src = { ROOT_API + '/uploads/' + props.data.image_four.image_url}
                    className=" grid-image"
                    alt={props.data.image_four.image_url}
                    // onMouseEnter={() => setVisible(props.data.image_three.id)}
                  />
                </div> 
                </Link>
              <div className={`grid-images-text ${classes.gridListHover}`} >
                <div className="grid-images-text__desc">{props.data.image_four.hover_text}</div>
                <button className="homepage-grid-images-button">
                  <Link className='button-black' to={`/service/${props.data.image_four.submenu_id}/${props.data.image_four.submenu_tab_id ? 'tab/' + props.data.image_four.submenu_tab_id : '' }`}>ПОДРОБНЕЕ</Link>
                </button>
              </div>
            </div>
            <div className={`grid-list-item ${classes.gridList}`} style={{width: '33%'}}>
              {/* <img src={ROOT_API + '/uploads/' + props.data.image_five.image_url}
                   className="grid-image"
                   alt={props.data.image_five.image_url}/> */}
                   <Link to={`/service/${props.data.image_five.submenu_id}/${props.data.image_five.submenu_tab_id ? 'tab/' + props.data.image_five.submenu_tab_id : '' }`}>
              <div className='overlay zoom view' data-aos="fade-up">
                  <img
                    src = { ROOT_API + '/uploads/' + props.data.image_five.image_url}
                    className=" grid-image"
                    alt={props.data.image_five.image_url}
                    // onMouseEnter={() => setVisible(props.data.image_three.id)}
                  />
              </div>
              </Link>
              <div className={`grid-images-text ${classes.gridListHover}`}>
                <div className="grid-images-text__desc">{props.data.image_five.hover_text}</div>
                <button className="homepage-grid-images-button">
                  <Link className='button-black' to={`/service/${props.data.image_five.submenu_id}/${props.data.image_five.submenu_tab_id ? 'tab/' + props.data.image_five.submenu_tab_id : '' }`}>ПОДРОБНЕЕ</Link>
                </button>
              </div>
            </div>
            <div className={`grid-list-item ${classes.gridList}`} style={{width: '33%', paddingRight: 0}}>
              {/* <img src={ROOT_API + '/uploads/' + props.data.image_six.image_url} className="grid-image"
                   alt={props.data.image_six.image_url}/> */}
                   <Link to={`/service/${props.data.image_six.submenu_id}/${props.data.image_six.submenu_tab_id ? 'tab/' + props.data.image_six.submenu_tab_id : '' }`}>
              <div className='overlay zoom view' data-aos="fade-up">
                  <img
                    src = { ROOT_API + '/uploads/' + props.data.image_six.image_url}
                    className=" grid-image"
                    alt={props.data.image_six.image_url}
                    // onMouseEnter={() => setVisible(props.data.image_three.id)}
                  />
              </div>
              </Link>
                   <div className={`grid-images-text ${classes.gridListHover}`}>
                     <div className="grid-images-text__desc">{props.data.image_six.hover_text}</div>
                     <button className="homepage-grid-images-button">
                       <Link className='button-black' to={`/service/${props.data.image_six.submenu_id}/${props.data.image_six.submenu_tab_id ? 'tab/' + props.data.image_six.submenu_tab_id : '' }`}>ПОДРОБНЕЕ</Link>
                     </button>
                   </div>
            </div>
          </div>
        </Grid>
      </div>
      :
      <Fragment>
        <Carousel {...settings} className="four-image-content mobile-home"
                  emulateTouch={true}
                  swipeable={true}
                  showThumbs={false} showStatus={false}
                  showArrows={false} infiniteLoop={false}
                  showIndicators={false}>

          { array1.map((tile, index) => (
            <Fragment key={tile} >
              <div className="position">
                <hr className="home-hr"/>
                <div className="home-hr-title">{ tile.hover_text}</div>
              </div>
                <Link to={`/service/${tile.submenu_id}/${tile.submenu_tab_id ? 'tab/' + tile.submenu_tab_id : '' }`}>
                  <img src={ROOT_API + '/uploads/' + tile.mobile_image}
                      alt={tile.submenu_id}
                  />
                </Link>
              <Link className='button-black' to={`/service/${tile.submenu_id}/${tile.submenu_tab_id ? 'tab/' + tile.submenu_tab_id : '' }`}>
                <button className='button-black'>
                  Подробнее
                </button>
              </Link>
            </Fragment>
          ))}
        </Carousel>
        <Carousel {...settings} className="four-image-content mobile-home"
                  emulateTouch={true}
                  swipeable={true}
                  showThumbs={false} showStatus={false}
                  showArrows={false} infiniteLoop={false}
                  showIndicators={false}>

          { array2.map((tile, index) => (
            <Fragment key={tile} >
              <div className="position">
                <hr className="home-hr"/>
                <div className="home-hr-title">{ tile.hover_text}</div>
              </div>
              <Link to={`/service/${tile.submenu_id}/${tile.submenu_tab_id ? 'tab/' + tile.submenu_tab_id : '' }`}>
                <img src={ROOT_API + '/uploads/' + tile.mobile_image}
                    alt={tile.submenu_id}
                />
              </Link>

              <Link className='button-black' to={`/service/${tile.submenu_id}/${tile.submenu_tab_id ? 'tab/' + tile.submenu_tab_id : '' }`}>
                <button className='button-black'>
                  Подробнее
                </button>
              </Link>
            </Fragment>
          ))}
        </Carousel>
      </Fragment>

  );
}

ImageGridList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageGridList);
