import React, {Fragment} from 'react';
import Collapsible from 'react-collapsible';
import {
  isBrowser,
} from "react-device-detect";

export default (props)=> {
  if (isBrowser) {
    return(
      <div className="interested-questions">
        <h2 className="interested-questions__question cosmetics-row" dangerouslySetInnerHTML={{__html: props.title}}>
        </h2>
        <div className="interested-questions__answer" dangerouslySetInnerHTML={{__html: props.description}}>
        </div>
      </div>
    )
  }
  return(
    <Fragment>
      <div className="position">
        <hr className="home-hr"/>
      </div>
      <Collapsible className="menu-item" trigger={props.title} >
        <div dangerouslySetInnerHTML={{__html: props.description}} className="text"></div>
      </Collapsible>
    </Fragment>

  )
}
