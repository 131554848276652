import axios from 'axios';
import { ROOT_API } from '../constants/';

export default (headers) => {
  const service = axios.create({
    baseURL: ROOT_API,
    headers: {
      ...headers
    }
  });
  service.interceptors.response.use(
    response => response,
    error => {

    }
  );
  return service;
}
