import React, {useState, useEffect} from 'react';
import GridImage from '../Helpers/gridImages';
import axios from '../../services/axios';
import {
  isBrowser
} from "react-device-detect";

import MetaData from "../Helpers/meta_data";

import {Link} from "react-router-dom";
import {ROOT_API} from "../../constants";
import {Carousel} from "react-responsive-carousel";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Close from '../../images/close.svg';
// import ReactImageMagnify from 'react-image-magnify';
import AOS from 'aos';
import 'aos/dist/aos.css';



const useStyles = theme => ({
    root: {
    margin: 0,
  }
});



const Home = (props) => {
  AOS.init({
    duration : 1500
  })
  const [open, setOpen] = React.useState(false);
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [data, setData] = useState({});
  useEffect(()=> {
    axios().get('home')
      .then(response => setData(response.data))
      .catch(error => console.log('Home', error));
  }, []);
  if(data) {
    window.scrollTo(0, 0);
  }
  return (
    !data.centeral_title ? <div></div> :
    <div className="solarium home">
    <MetaData data={data.meta}/>
    {isBrowser ? 
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={Close} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
          : <div></div>}
      
      <div className='title-and-description-position'>
        {/* <h1> {data.title} </h1> */}
        <div className="title">{data.title}</div>
        {isBrowser ? <div className="text" dangerouslySetInnerHTML={{__html: data.headText}}></div>
          : <div></div>}
      </div>
      <div className="home-page-image-grid">
        <GridImage data={data.images}/>
      </div>
      <div className="center-title-and-description" data-aos="fade-up">
        <h1> {data.centeral_title} </h1>
        <div className="text" dangerouslySetInnerHTML={{__html: data.centeral_text}}></div>
        <div className='buttons-position '>
          {isBrowser ?
            <Link className='button-black' to={`/service/${data.central_sub_menu_id}`}>
              <button>
                ПОДРОБНЕЕ
              </button>
            </Link>
          : <div></div>}
            {/* <button className='homepage-content-booknow' onClick={handleClickOpen}>Онлайн-запись</button> */}
          
        </div>
      </div>
      <div className="text-with-images homepage-three-products" >
        <div className="images-content floatRight">
          {
            isBrowser ?
              <div className="position" data-aos="fade-up">
                <div className="four-image-content">
                  {Object.keys(data.content_product.images).map((item) =>
                    <div className="four-image-item homepage-product overlay zoom view" key={item}>
                      <Link  to={`/service/${data.content_product.sub_menu_id}`}>
                      <img src={ROOT_API+'/uploads/'+data.content_product.images[item]} alt=""/>
                    </Link>
                    </div>
                  )}
                </div>
              </div>
              :
              <Carousel {...settings} className="four-image-content "
                        emulateTouch={true}
                        swipeable={true} showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={false}>
                {Object.keys(data.content_product.images).map((item) =>
                  <Link  to={`/service/${data.content_product.sub_menu_id}`}>
                    <div className="four-image-item " key={item}>
                      <img src={ROOT_API+'/uploads/'+data.content_product.images[item]} alt=""/>
                    </div>
                  </Link>
                )}
              </Carousel>
          }
        </div>
        <div className="text-with-images__text floatLeft" data-aos="fade-up">
          <h1> {data.content_product.title} </h1>
          <div className="text" dangerouslySetInnerHTML={{__html: data.content_product.text}}></div>
            <Link to={`/service/${data.content_product.sub_menu_id}`}>
              <button className="details-button homepage-products-button">
                ПОКАЗАТЬ КОСМЕТИКУ
              </button>
            </Link>
        </div>
      </div>
    </div>

);
}

export default withStyles(useStyles)(Home)