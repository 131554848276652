import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Carousel} from 'react-responsive-carousel';
import {
  isBrowser,
} from "react-device-detect";
import {ROOT_API} from '../../constants/index'
import AOS from 'aos';
import 'aos/dist/aos.css';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

export default (props) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  AOS.init({
    duration : 800
  })
  return (
    <div className='header-content'>
      <div>
        <div className="title" dangerouslySetInnerHTML={{__html: props.title}}>
        </div>
        <Grid item xs={10}  sm={10} lg={6} md={8} width={100}>
          <div className="text" dangerouslySetInnerHTML={{__html: props.text}}>
          </div>
        </Grid>
      </div>
      {
        isBrowser ?
          <div className="images-content" data-aos="fade-up">
            {Object.keys(props.images).map((item) =>
              <div className="solarium-images overlay zoom view" key={item}>
                <img src={ROOT_API + '/uploads/' + props.images[item]}
                                                               alt=""/>
              </div>
            )}
          </div>
          :
          <Carousel {...settings} className="four-image-content"
                    emulateTouch={true}
                    swipeable={true}
                    showThumbs={false} showStatus={false}
                    showArrows={false} infiniteLoop={false}
                    showIndicators={false}>
            {Object.keys(props.images).map((item) =>
              <div className="four-image-item overlay zoom view" key={item}>
                <img src={ROOT_API + '/uploads/' + props.images[item]} alt=""/>
              </div>
            )}
          </Carousel>
      }

    </div>
  )
}
