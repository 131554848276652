import React, {useEffect, useState, Fragment} from 'react';
import ContentHeaderPart from '../Helpers/contentHeaderPart';
import ImagesWithTextAndProduct from '../Helpers/imagesWithTextAndProduct';
import BeautySalonMobile from '../Helpers/beautySalonMobile';
import axios from "../../services/axios";
import {
  isBrowser
} from "react-device-detect";
import MetaData from "../Helpers/meta_data";
import { createBrowserHistory } from 'history';



export default({data, service_id}, props) => {
  const [headerData, setHeaderData] = useState({});
  const tab = window.location.pathname.split('tab/')[1] || Object.values(data.salon_tabs)[0].id;
  const [tabContent, setTabContent] = useState(tab);
  const history = createBrowserHistory();

  if(!data || data === undefined) {
    const [data, setData] = useState({});
    useEffect(() => {
      axios().get('/services/5')
        .then(response => {
          setData(response.data);
          axios().get(`/submenu-headers/${data.submenu_headers_id}`)
            .then(response => setHeaderData(response.data))
          }
        )
        .catch(error => console.error(error));
        window.scrollTo(0, 0);

    }, []);
  } else{
  useEffect(() => {
    axios().get(`/submenu-headers/${data.submenu_headers_id}`)
      .then(response => setHeaderData(response.data))
      .catch(error => console.error(error));
      window.scrollTo(0, 0);

  }, []);
}

  let images = [];
  for (let key in headerData) {
    if (key.indexOf('image') > -1) {
      images[key] = headerData[key];
    }
  }
  data.meta_data.og_imagae = !data.meta_data.og_image ? images.image_one : data.meta_data.og_image;
  return (
    !data.salon_tabs ? <div></div> :
      <div className="solarium container beauty-salon-page">
        <MetaData data={data.meta_data}/>
        <ContentHeaderPart title={headerData.title} text={headerData.text} images={images}/>
        {isBrowser ?
          <Fragment>
            <div>
              <div className="content-menu">
                {Object.values(data.salon_tabs).map(item => <div
                  className={`content-menu-items ${tabContent == item.id ? 'active-tab bs-active-tab' : ''}`}
                  key={item.id}
                  onClick={() => {
                    setTabContent(item.id);
                    history.push('/service/' + service_id + '/tab/'+item.id)
                  }}>
                  {item.name}
                  </div>
                )}
              </div>
            </div>
            {
              Object.values(data.salon_tabs).map(items =>
                items.salon_blocks.map((item, key) =>
                  item.product_list ?
                  <ImagesWithTextAndProduct index={key} item={item.salons_tabs_id == tabContent ? item : ''}/>
                  : <div></div>
                )
              )
            }
          </Fragment>
          :
            <BeautySalonMobile data={data}/>
        }
      </div>
      )
}
