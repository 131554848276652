import React, {useEffect, useState, Fragment} from 'react';
import ContentHeaderPart from '../Helpers/contentHeaderPart';
import CoastTable from '../Helpers/coastTable';
import TitleAndDescription from '../Helpers/titleAndDescription';
import {isBrowser} from "react-device-detect";
import axios from '../../services/axios';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import { withStyles } from '@material-ui/core';
import {Carousel} from "react-responsive-carousel";
import MetaData from '../Helpers/meta_data';
import {ROOT_API} from "../../constants";
import Title from "../Helpers/title";
import PropTypes from "prop-types";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseSvg from '../../images/close.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin:'0 !important',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

function TanningGrid(props){
  const { classes } = props;
  const {data} = props;
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [headerData, setHeaderData] = useState({});
  const [visible, setVisible] = useState({isFirstOpen : true, isSecondOpen: true});
  useEffect(() => {
    axios().get(`/submenu-headers/${data.submenu_headers_id}`)
      .then(response => setHeaderData(response.data))
      .catch(error => console.error(error));
  }, [data.submenu_headers_id]);
  let images = [];
  for (let key in headerData) {
    if (key.indexOf('image') > -1) {
      images[key] = headerData[key];
    }
  }
  data.meta_data.og_imagae = !data.meta_data.og_image ? images.image_one : data.meta_data.og_image;

  const [open, setOpenModal] = React.useState(false);
  function handleClickOpen() {
    setOpenModal(true);
  }

  function handleClose() {
    setOpenModal(false);
  }

  if(data) {
    window.scrollTo(0, 0);
  }
  
  return(
    <div className="solarium container tanning">
      <MetaData data={data.meta_data}/>
      {isBrowser ? 
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={CloseSvg} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
          : <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={CloseSvg} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>}
      {    isBrowser ?
        <Fragment>
          <ContentHeaderPart title={headerData.title} text={headerData.text} images={images}/>
          <Title title={data.content_title}/>
          <div className="about-texts">
            <Grid container className={ classes.root } spacing={ 38 }  justify="space-between">
              <Grid container className={ classes.demo } justify="space-between" spacing={ 40 }>
                <Grid item sm={ 3 } xs={ 3 } md={ 6 } lg={ 6 }>
                  <div className="{classes.paper} about-texts__items" dangerouslySetInnerHTML={{__html: data.content_text_one}}>
                  </div>
                </Grid>
                <Grid item sm={ 3 } xs={ 3 } md={ 6 } lg={ 6 }>
                  <div className="{classes.paper} about-texts__items" dangerouslySetInnerHTML={{__html: data.content_text_two}}>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className="video-and-text-content">
            <div className="video-content">
              <div className="menu-image-content floatLeft">
                <div className="menu-image-item overlay zoom view" data-aos="fade-up"><img src={ROOT_API + '/uploads/' + data.image} alt=""/></div>
              </div>
            </div>
            <div className={`text-content ${visible.isFirstOpen ? '' : ''}`} >
              <TitleAndDescription title={data.title} description={(visible.isFirstOpen ? data.text.substring(0,375) : data.text)} />
              {data.text.length > 300 ?
                <div className="position see-details inner-see-details">
                  <div onClick={()=>{
                    setVisible({isFirstOpen : !visible.isFirstOpen,isSecondOpen : visible.isSecondOpen })
                    if(!visible.isFirstOpen) window.scrollTo(0,0);
                  }} className="floatRight">{visible.isFirstOpen ? 'Подробнее' : 'Свернуть'}</div>
                  <hr className="home-hr floatRight top10"/>
                </div>
                : <div></div>
              }
              <CoastTable className="tanning-coast-table" list={data.product_list}/>
              <div className="button-position">
                  <button className="button-black tips-and-questions__button" onClick={handleClickOpen}>
                    Онлайн-запись
                  </button>
              </div>
            </div>
          </div>
        </Fragment>

        :
        <Fragment>
          <ContentHeaderPart title={headerData.title} text={headerData.text} images={images}/>

          <h2 className="tanning-title">{headerData.title}</h2>
          <Grid container className='collapse-grid' spacing={ 40 } justify="space-between">
            <Grid container justify="space-between" spacing={ 40 }>
              <Grid key={ data.id } item sm={10} xs={10} md={ 3 } lg={ 3 } width={ 100 }>
                <Paper className="{classes.paper} tanning">
                  <div className="video-and-text-content">
                    
                    <div className={`${visible.isSecondOpen ? 'hidden_questions' : ''}`}>
                      <div className="text" dangerouslySetInnerHTML={{__html: data.text}}>
                    </div>
                      {data.text.length > 300 ?
                        <div className="position see-details inner-see-details">
                          <div onClick={()=>{
                            setVisible({isFirstOpen: visible.isFirstOpen, isSecondOpen: !visible.isSecondOpen})
                            if(!visible.isFirstOpen) window.scrollTo(0,0);
                          }} className="floatRight">
                            {visible.isSecondOpen ? 'Читать далее' : 'Свернуть'}</div>
                          <hr className="home-hr floatRight top10"/>
                        </div>
                        : <div></div>
                      }

                    </div>
                    <div className="button-div position">
                      <button className='button-black' onClick={handleClickOpen}>Онлайн-запись</button>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

      }
    </div>
      )
}

TanningGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(TanningGrid);