import React from 'react';
import ReactDOM from 'react-dom';
import {ROOT_API} from "../../constants";
import {Carousel} from "react-responsive-carousel";

export default(props) => {
  let { images } = props;
  let settings = {
    dots: false,
    arrow: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  let handle =  function(){
    let nextEl = document.querySelector('.selected ~ .slide ~ .slide ~ .slide ~ .slide ~ .slide ~ .slide>div');
    let arrow = document.querySelector('.control-next');
    arrow.style.display = "block";
    if(nextEl && nextEl !==  undefined && nextEl!= null && nextEl.classList && nextEl.classList.contains('last-li-element')) {
      arrow.style.display = "none";
    }
  }

  return(
    !images.data ? <div></div>:
      <Carousel {...settings} className=" four-image-content" swipeable={true} showThumbs={false}
                showStatus={false}
                emulateTouch={false}
                showIndicators={false}
                showArrows={true} 
                infiniteLoop={false}
                onChange={()=>handle()}>
        <div className="last-li-element">
        </div>
          {images.data.map(item =>
                <div>
                  <img src={ROOT_API + '/uploads/' + item.image}  key={item.id} alt=""/>
                </div>
            )}
        <div className="last-li-element">
        </div>
      </Carousel>
  )
}