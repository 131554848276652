import React, {useState, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {ROOT_API} from "../../constants";

export default ({content, setContent, setActiveMenu, setIsCollapseOpen}) => {
  const [visible, setVisible] = useState(0);
  return (
    !content ? '' : !content.submenus ? <div></div> :
      <div className="collapse submenu_collapse" >
        <div className="submenu-content">
          <ul className="submenu">
            {
              content.submenus.map((item, index) =>
               <Fragment><li
                 className={`submenu-items_${index}`}
                 key={item.id}
                 onClick={() => setContent(null)}
               >
                 <Link to={`/service/${item.id}`} onMouseEnter={() => setVisible(index)}
                         onClick={() => setActiveMenu({id:content.id})}>
                   {item.name}
                 </Link>
               </li>
               </Fragment>)
            }
          </ul>
        </div>
        <div className="images-content">

          {content.submenus && content.submenus.map((item, index) => {
              if (item.design_type.name === 'Cosmetics') {
                return (
                  <div>
                    
                    <div className={`images ${visible === index ? 'display-block' : 'display-none'}`} key={item.id}>
                      <Link to={`/service/${item.id}`}>
                        <img src={`${ROOT_API}/uploads/${item.cosmetics_page.cosmetic.cosmetic_products[0].image}`} alt=""/>
                      </Link>                  
                    </div>
                    <div className={`images ${visible === index ? 'display-block' : 'display-none'}`} key={item.id}>
                      <Link to={`/service/${item.id}`}>
                        <img src={`${ROOT_API}/uploads/${item.cosmetics_page.cosmetic.cosmetic_products[1].image}`} alt=""/>
                      </Link>                  
                    </div>
                  </div>
                ) 
              } else {
                return item.submenu_header && Object.values(item.submenu_header).map((submenu, key) =>
                  <div className={`images ${visible === index ? 'display-block' : 'display-none'}`} key={key}>
                    <Link to={`/service/${item.id}`}>
                      <img src={`${ROOT_API}/uploads/${submenu}`} alt=""/>
                    </Link>
                  </div>
                )
              }
            }
          )}
        </div>
      </div>
  )
}