import TitleAndDescription from "./titleAndDescription";
import CoastTable from "./coastTable";
import {ROOT_API} from "../../constants";
import React, {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloseSvg from '../../images/close.svg';
import {isBrowser} from "react-device-detect";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default (props)=>{
  const [open, setOpenModal] = React.useState(false);
  const [visible, setVisible] = useState({isSecondOpen: true, isFirstOpen: true});
  function handleClickOpen() {
    setOpenModal(true);
  }
  AOS.init({
    duration : 800,
    once: true,

  })

  function handleClose() {
    setOpenModal(false);
  }


  let {item, index} = props;
  return(
    !item ? <div></div> :
      <Fragment>
        {isBrowser ? 
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={CloseSvg} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
          : <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={CloseSvg} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>}
      {!(index % 2) ?
        <div className="video-and-text-content">
          <div className={`text-content floatLeft ${visible.isFirstOpen ? '' : ''}`}>
            <TitleAndDescription title={item.title} description={(visible.isFirstOpen ? item.text.substring(0,375) : item.text)}/>
            {item.text.length > 300 ?
              <div className="position see-details">
                <div onClick={()=> {
                  setVisible({isFirstOpen : !visible.isFirstOpen, isSecondOpen : visible.isSecondOpen });
                  if(!visible.isFirstOpen) window.scrollTo(0,0);
                }} className="floatRight">
                  {visible.isFirstOpen ? 'Читать далее' : 'Свернуть'}</div>
                <hr className="home-hr top10 floatRight"/>
              </div>
              : <div></div>
            }
            {!item.product_list ? <div></div> :
              <CoastTable className="tanning-coast-table" list={item.product_list}/>
            }
          </div>
          <div>
            <div className="menu-image-content" data-aos="fade-up">
              <div className="menu-image-item overlay zoom view"><img src={ROOT_API + '/uploads/' + item.image_one} alt=""/></div>
              <div className="menu-image-item overlay zoom view"><img src={ROOT_API + '/uploads/' + item.image_two} alt=""/></div>
            </div>
            <div className="beauty-salon-f-text floatRight" dangerouslySetInnerHTML={{__html: item.f_text}}></div>

            {/* <div className="menu-image-content center-and-top">
              <div className="menu-image-item"><img src={ROOT_API + '/uploads/' + item.image_three} alt=""/>
              </div>
            </div> */}
            <div className="button-position">
              <button className="button-black tips-and-questions__button" onClick={handleClickOpen}>
                Онлайн-запись
              </button>
            </div>
          </div>
          {item.end_line ?
            <div className="beauty-salon-hr">
              < hr />
            </div>
            :
            <div className="beauty-salon-without-hr"></div>
          }
        </div>
        :
        <div className="video-and-text-content">
          <div className={`text-content floatRight ${visible.isSecondOpen ? '' : ''}`}>
            <TitleAndDescription title={item.title} description={(visible.isSecondOpen ? item.text.substring(0,375) : item.text)}/>
            {item.text.length > 300 ?
              <div className="position see-details">
                <div onClick={()=> {
                  setVisible({isSecondOpen : !visible.isSecondOpen, isFirstOpen : !visible.isFirstOpen })
                  if(!visible.isSecondOpen) window.scrollTo(0,0);
                }} className="floatRight">
                  {visible.isSecondOpen ? 'Читать далее' : 'Свернуть'}</div>
                <hr className="home-hr top10 floatRight"/>
              </div>
              : <div></div>
            }
            {!item.product_list ? <div></div> :
              <CoastTable className="tanning-coast-table" list={item.product_list}/>
            }
          </div>
          <div className="three-images-with-text">
            <div className=" floatLeft menu-image-content">
              <div className="menu-image-item"><img src={ROOT_API + '/uploads/' + item.image_one} alt=""/></div>
              <div className="menu-image-item"><img src={ROOT_API + '/uploads/' + item.image_two} alt=""/></div>
            </div>
            <div className="floatLeft menu-image-content center-and-top">
              <div className="menu-image-item"><img src={ROOT_API + '/uploads/' + item.image_three} alt=""/>
              </div>
            </div>
            <div className="button-position">
              <button className="button-black tips-and-questions__button" onClick={handleClickOpen}>
                Онлайн-запись
              </button>
              <div className="beauty-salon-f-text floatLeft" dangerouslySetInnerHTML={{__html: item.f_text}}></div>
            </div>
          </div>
          {item.end_line ?
            <div className="beauty-salon-hr">
              < hr />
            </div>
            :
            <div className="beauty-salon-hr"></div>
          }
        </div>
      }

    </Fragment>
  )
}