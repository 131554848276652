import React, { useEffect, useState, Fragment } from 'react';
import FourImagesBlock from '../Helpers/fourImagesBlock';
import Title from '../Helpers/title';

export default(props) => {
  const [visible, setVisible] = useState({isOpen : false});
  let collapseText = visible.isOpen ? 'Свернуть' : 'Читать далее';
  let { item } = props;
  let images = [];
  for (let key in item) {
    if (key.indexOf('image') > -1) {
      images[key] = item[key];
    }
  }
  return(
    !item.price_text ? <div></div> :
      <Fragment>
        <FourImagesBlock images={images}/>
        <Title title={item.title} />
        { visible.isOpen ?
          <div className="coll-text" dangerouslySetInnerHTML={{__html: item.text}}>
          </div> :
          <div></div>
        }
        <div className="cost coll-text">
          <div>Цена։</div>
          <div dangerouslySetInnerHTML={{__html: item.price_text}}></div>
        </div>
        <div className="show-less-more">
          <hr/>
          <div onClick={()=> {
              setVisible({isOpen : !visible.isOpen})
              if(visible.isOpen) window.scrollTo(0,0);
          }}>{collapseText}</div>
        </div>
      </Fragment>


  )
}