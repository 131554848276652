import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Grid from '@material-ui/core/Grid/Grid';
import { ROOT_API } from '../../constants/index'
import {Link} from "react-router-dom";
import {
  isBrowser,
} from "react-device-detect";
import AOS from 'aos';
import 'aos/dist/aos.css';


export default(props) => {

    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    AOS.init({
      duration : 800
    })
    let  footer_components = undefined
    if (props.footer_components != undefined) {
      footer_components =  Object.keys(props.footer_components)
    }
    if (isBrowser) {
      return (
        <div className="position">
          <div className="four-image-content " data-aos="fade-up">
            {
            props.footer_components != undefined ? 
              Object.keys(props.images).map((item, index) => 
                <div className="four-image-item overlay zoom view" key={item}>
                  <Link to ={`/service/${props.footer_components[footer_components[index]].submenu_id}/${props.footer_components[footer_components[index]].submenu_tab_id ? 'tab/' + props.footer_components[footer_components[index]].submenu_tab_id : '' }`} >
                    <img src={ROOT_API+'/uploads/'+props.images[item]} alt="888"/>
                  </Link >
                </div>
              )
              : 
              Object.keys(props.images).map((item) => 
                <div className="four-image-item" key={item}>
                  <img src={ROOT_API+'/uploads/'+props.images[item]} alt=""/>
                </div>
              ) 
                  
            }
          </div>
        </div>

      )
    }
    return (
      <Carousel {...settings} className="four-image-content"
                emulateTouch={true}
                swipeable={true}
                showThumbs={false} showStatus={false}
                showArrows={false} infiniteLoop={false}
                showIndicators={false}>
        { props.footer_components != undefined ?
          Object.keys(props.images).map((item, index) =>
            <div className="four-image-item" key={item}>
              <Link to ={`/service/${props.footer_components[footer_components[index]].submenu_id}/${props.footer_components[footer_components[index]].submenu_tab_id ? 'tab/' + props.footer_components[footer_components[index]].submenu_tab_id : '' }`} >
                <img src={ROOT_API+'/uploads/'+props.images[item]} alt=""/>
              </Link >
            </div>
        ) : 
        Object.keys(props.images).map((item) =>
          <div className="four-image-item" key={item}>
            <img src={ROOT_API+'/uploads/'+props.images[item]} alt=""/>
          </div>
        )}
      </Carousel>
    );
};
