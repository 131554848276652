import React, { useState, Fragment } from 'react';
import FourImagesBlock from '../Helpers/fourImagesBlock';
import Title from '../Helpers/title';
import CoastTable from "./coastTable";

export default(props) => {
  let { item } = props;
  const [visible, setVisible] = useState({isOpen : false});
  let collapseText = visible.isOpen ? 'Свернуть' : 'Читать далее';

  let images = [];
  for (let key in item) {
    if (key.indexOf('image') > -1) {
      images[key] = item[key];
    }
  }
  return(
    !item.product_list ? <div></div> :
      <Fragment>
        <FourImagesBlock images={images}/>
        <Title title={item.title}/>
        { visible.isOpen ?
          <div className="coll-text" dangerouslySetInnerHTML={{__html: item.text}}>
          </div> :
          <div className="coll-text type_call">Наименование услуг</div>
        }
        {!item.product_list ? <div></div> :
          <Fragment>
            <div className="coll-text price_call">Цена։</div>
            {item.product_list.map(item=>
            <div className="cost coll-text">
              <div className="row">
              <div className="floatLeft" dangerouslySetInnerHTML={{__html: item.product}}></div>
              <div className="floatRight" dangerouslySetInnerHTML={{__html: item.price}}></div>
              </div>
            </div>
            )}
          </Fragment>
        }
        <div className="show-less-more">
          <hr/>
          <div onClick={()=> {setVisible({isOpen : !visible.isOpen})}}>{collapseText}</div>
        </div>
      </Fragment>


  )
}