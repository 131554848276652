import React, { useEffect, useState } from 'react';
import ContentHeaderPart from '../Helpers/contentHeaderPart';
import FourImagesBlock from '../Helpers/fourImagesBlock';
import MetaData from '../Helpers/meta_data';
import TitleAndDescription from '../Helpers/titleAndDescription';
import Title from '../Helpers/title';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from '../../services/axios';
import {Link} from "react-router-dom";
import {Carousel} from 'react-responsive-carousel';
import { ROOT_API } from '../../constants/index'

import {
  isBrowser,
} from "react-device-detect";
import AOS from 'aos';
import 'aos/dist/aos.css';
const styles = theme => ({
  root: {
    flexGrow: 1,
    margin:'0 !important',
    width: '99% !important'
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

let mobileSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};


function CenteredGrid(props) { 
  const { classes } = props;
  const [data, setData] = useState({});
  useEffect(()=> {
    axios().get('about')
      .then(response => setData(response.data))
      .catch(error => console.log('About', error));
  }, []);
  let url = '/';
  if(data) {
    window.scrollTo(0, 0);
  }
  return (
    !data.images ? <div></div> :
    <div className="solarium container about">
      <MetaData data={data.meta}/>
      <ContentHeaderPart title={data.title} text={data.headText} images={data.images}/>
      <Title title={data.centeral_title}/>
      <div className="about-texts">
        <Grid container className={ classes.root } spacing={ 40 } justify="space-between">
          <Grid container className={ classes.demo } justify="space-between" spacing={ 40 }>
              <Grid item sm={ 6 } xs={ 8 } md={ 6 } lg={ 6 }>
                <div className="{classes.paper} about-texts__items" dangerouslySetInnerHTML={{__html: data.centeral_text}}>
                </div>
              </Grid>
            <Grid item sm={ 6 } xs={ 8 } md={ 6 } lg={ 6 }>
              <div className="{classes.paper} about-texts__items" dangerouslySetInnerHTML={{__html: data.centeral_text_two}}>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {
        isBrowser ? <FourImagesBlock images={data.footer_images} footer_components ={data.footer_components}/> : <div></div>
      }
      {
        isBrowser ? 
        <div className="four-title-and-description ">

          { Object.keys(data.footer_components).map(key => (

                <div className="interested-questions">
                  <h2 className="interested-questions__question about-page-texts"dangerouslySetInnerHTML={{__html: data.footer_components[key].title}}>
                  </h2>
                  <div className="interested-questions__answer" dangerouslySetInnerHTML={{__html: data.footer_components[key].text}}>
                  </div>
                </div>
          ))}
        </div> : <div></div>
      }
      {/* {
        isBrowser ? <div></div> : <FourImagesBlock images={data.footer_images} footer_components ={data.footer_components}/>
      } */}
      {
        isBrowser ? <div></div> :
        <Carousel {...mobileSettings} className="four-image-content" swipeable={true} showThumbs={false}
          emulateTouch={true}
          showIndicators={false}
          showStatus={false}
          showArrows={false} infiniteLoop={false}>
          {
            Object.keys(data.footer_components).map( (key,index) => (
              <div>
                <div className="four-image-item" key={key}>
                  <img src={ROOT_API+'/uploads/'+Object.values(data.footer_images)[index]} alt=""/>
                </div>
                
                <div className="interested-questions">
                  <h2 className="interested-questions__question about-page-texts"  style={{"margin-left": `${index*5}%`}} dangerouslySetInnerHTML={{__html: data.footer_components[key].title}}>
                  </h2>
                  <div className="interested-questions__answer"  style={{"margin-left": `${index*5}%`}} dangerouslySetInnerHTML={{__html: data.footer_components[key].text}}>
                  </div>
                  <Link to={ data.footer_components[key].submenu_id == undefined ? '/' :
                                data.footer_components[key].submenu_tab_id == undefined ?
                                  `/service/${data.footer_components[key].submenu_id}` :
                                  `/service/${data.footer_components[key].submenu_id}/tab/${data.footer_components[key].submenu_tab_id}`}>
                    <button className="details-button">
                      Подробнее
                    </button>
                  </Link>
                </div>
              </div>
          ))}
        </Carousel>


      }
      
      {isBrowser ?
        <div className="four-title-and-description four-title-and-description-distance">
              { Object.keys(data.footer_components).map(key => (
                  <Link to={ data.footer_components[key].submenu_id == undefined ? '/' :
                    data.footer_components[key].submenu_tab_id == undefined ?
                      `/service/${data.footer_components[key].submenu_id}` :
                      `/service/${data.footer_components[key].submenu_id}/tab/${data.footer_components[key].submenu_tab_id}`}>
                    <button className="details-button">
                      Подробнее
                    </button>
                  </Link>
              ))}
        </div>
        : <div></div>}
    </div>
  );
}

CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CenteredGrid);
