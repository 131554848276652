import React, {Fragment} from 'react';
import TitleAndDescription from '../Helpers/titleAndDescription';
import {Carousel} from 'react-responsive-carousel';
import {ROOT_API} from '../../constants';
import {Link} from "react-router-dom";
import MetaData from "../Helpers/meta_data";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  isBrowser
} from "react-device-detect";
import AOS from 'aos';
import 'aos/dist/aos.css'

function truncateText(text, maxLength) {
  // If the text length is less than or equal to the maxLength, return the text
  if (text.length <= maxLength) {
      return text;
  }

  // Trim the text to the desired length
  let truncatedText = text.substring(0, maxLength);

  // Remove any incomplete word at the end
  truncatedText = truncatedText.replace(/\s+\S*$/, '');

  // Remove trailing newline if exists
  truncatedText = truncatedText.replace(/\n$/, '');

  // Add ellipsis at the end
  truncatedText += '...';

  return truncatedText;
}


export default ({data}) => {
  AOS.init({
    duration : 800
  })
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
  };
  let mobileSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  if(data) {
    window.scrollTo(0, 0);
  }
  return (
    !data.cosmetics ? <div></div> :
      <div className="cosmetics-inner cosmetics-page-position">
        <MetaData data={data.meta_data}/>
        {data.cosmetics.map(items =>
          <Fragment>
            <div className="header-part position">
              <div className="interested-questions">
                <h2 className="interested-questions__question" dangerouslySetInnerHTML={{__html: items.title}}>
                </h2>
                <div className="interested-questions__answer" dangerouslySetInnerHTML={{__html: items.text}}>
                </div>
              </div>
            </div>
            { isBrowser ?
              <Fragment>
                <Carousel {...settings} className="four-image-content"
                          swipeable={true}
                          emulateTouch={true}
                          showThumbs={false}
                          showIndicators={false}
                          showStatus={false}
                          showArrows={true} infiniteLoop={false}>
                  {items.cosmetic_products.map(item =>
                    <Fragment>
                      <Link  to={`/cosmetic-product/${item.id}`}>
                        <div className="overlay zoom view" data-aos="fade-up">
                          <img src={ROOT_API + '/uploads/' + item.image} alt=""/>
                        </div>
                        
                      </Link>
                      <TitleAndDescription className="carousel" title={item.title} description={truncateText(item.text, 85)}/>
                      <div className="position cosmetics-see-details top10">
                        <hr className="home-hr"/>
                        <Link className='button-black' to={`/cosmetic-product/${item.id}`}>
                          <div className="floatLeft">Подробнее</div>
                        </Link>
                      </div>
                    </Fragment>
                  )}
                </Carousel>
              </Fragment>
            : <div>
                <Grid container className='collapse-grid' spacing={20} justify="space-between">
                  <Grid container justify="space-between" spacing={20}>
                    <Grid key={ items.id } item sm={12} xs={12} md={ 3 } lg={ 3 } width={ 100 }>
                      <Paper className="{classes.paper}">
                        <Carousel {...mobileSettings} className="four-image-content" swipeable={true} showThumbs={false}
                                  emulateTouch={true}
                                  showIndicators={false}
                                  showStatus={false}
                                  showArrows={false} infiniteLoop={true}>
                          {items.cosmetic_products.map(item =>
                            <Fragment>
                              <Link  to={`/cosmetic-product/${item.id}`} data-aos="fade-up">
                                <img src={ROOT_API + '/uploads/' + item.image} alt=""/>
                              </Link>
                              <div className="carousel">
                                <div className="interested-questions cosmetics-row">
                                  <h2 className="interested-questions__question " dangerouslySetInnerHTML={{__html: item.title}}>
                                  </h2>
                                  <div className="interested-questions__answer" dangerouslySetInnerHTML={{__html: truncateText(item.text, 85)}}>
                                  </div>
                                </div>
                              </div>
                              <div className="position cosmetics-see-details top10">
                                <hr className="home-hr"/>
                                <Link className='button-black' to={`/cosmetic-product/${item.id}`}>
                                  <div className="floatLeft">Подробнее</div>
                                </Link>
                              </div>
                            </Fragment>
                          )}
                        </Carousel>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              }

          </Fragment>
        )}
      </div>
  );
}

