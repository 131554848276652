import React from 'react';
import { BrowserRouter, Route, Switch, Navigate, Redirect } from 'react-router-dom';

import Main from './Main';
import Home from './Main/Home';
import NotFound from './Main/NotFound';
import About from './Main/About';
import Lpg from './Main/LpgMassage';
import Contact from './Main/Contact';
import CosmeticsInner from './Main/cosmeticsInner';

import Header from './Header/Header';
import Footer from './Footer';
import Map from './Helpers/map';
import BeautySalon from "./Main/BeautySalon";
import Solarium from "./Main/Solarium";

export default () => (

  <BrowserRouter>
    <Header />
    <Switch>
      <Redirect from='/cat/types/' to='/about' />
      <Redirect from='/cat/varianty_abonementov/' to='/service/7' />
      <Redirect from='/cat/ob_avtozagare/' to='/service/3' />
      <Redirect from='/cat/japan/' to='/service/5' />
      <Redirect from='/cat/emerald_bay/' to='/service/6' />
      <Redirect from='/cat/california_tan49/' to='/service/6' />
      <Redirect from='/cat/ed_hardy49/' to='/service/6' />
      <Redirect from='/cat/lpg/' to='/service/4' />
      <Redirect from='/cat/tanless/' to='/service/3' />
      <Redirect from='/cat/contacts/' to='/contact' />
      <Redirect from='/cat/waxing/' to='/service/5/tab/2' />
      <Redirect from='/cat/m_sukharevskaja3939/' to='/service/1' />
      <Redirect from='/cat/m_sukharevskaja39/' to='/service/1' />
      <Redirect from='/all/26/' to='/service/1' />
      <Redirect from='/chity/skini-devushek-dlya-minecraft-1-5-2-po-nika/' to='/home' />
      <Redirect from='/strashnie-skini-po-nikam-v-majnkraft-1-7-2/' to='/home' />
      <Redirect from='/karty/mod-superheroes-dlya-majnkraft-1-7-10/' to='/home' />
      <Redirect from='/sheders/majnkraft-z-200-modami-skachat/' to='/home' />
      <Redirect from='/sheders/mod-na-volshebnie-palochki-na-majnkraft-1-7-2/' to='/home' />
      <Redirect from='/skins/skachat-muzika-majnkraft-rep-kripera-skachat/' to='/home' />
      <Redirect from='/chity/skachat-majnkraft-1-5-2-s-modami-besplatno-bez-registratsii/' to='/home' />
      <Redirect from='/chity/skachat-mod-na-vintorez-dlya-majnkraft-1-7-10/' to='/home' />
      <Redirect from='/chity/teksturi-paki-na-majnkraft-1-8-9/' to='/home' />
      <Redirect from='/karty/lift-iz-vodi-minecraft/' to='/home' />
      <Redirect from='/karty/minecraft-1-8-9-tekstur-pak-hd/' to='/home' />
      <Redirect from='/skins/sborka-s-t-a-l-c-r-a-f-t-na-majnkraft-1-6-4/' to='/home' />
      <Redirect from='/skins/sborka-s-t-a-l-c-r-a-f-t-na-majnkraft-1-6-4/' to='/home' />
      <Redirect from='/karty/' to='/home' />
      <Redirect from='/karty/karti-bed-vars-na-majnkraft-1-8/' to='/home' />
      <Redirect from='/karty/majnkraft-pirati-mistik-i-lagerom/' to='/home' />
      <Redirect from='/karty/skachat-modu-na-majnkraft-s-drakonami/' to='/home' />
      <Redirect from='/karty/sozdaem-magazin-v-minecraft/' to='/home' />
      <Redirect from='/mods/skachat-skin-zemli-na-majnkraft-1-8/' to='/home' />
      <Redirect from='/sheders/majnkraft-postrojki-doma-na-dereve/video/' to='/home' />
      <Redirect from='/sheders/minecraft-mods-pe/video/' to='/home' />
      <Redirect from='/sheders/yarik-lapa-i-freddi-majnkraft-bez-granits-7-seriya/' to='/home' />
      <Redirect from='/map/' to='/contact' />
      <Route exact component={Home} path="/" />
      
      <Route component={Home} path="/home" />
      <Route component={CosmeticsInner} path="/cosmetic-product/:id?/" />
      <Route component={Main} path="/service/:id?/" />
      <Route component={BeautySalon} path="/service/:id?/tab/:tab?/" />
      <Route component={Solarium} path="/service/:id?/tab/:tab?/" />
      <Route component={About} path="/about" />
      <Route component={Lpg} path="/lpg" />
      <Route component={Contact} path="/contact" />
      <Redirect from='*' to='/home' />
    </Switch>
      <Map/>
    <Footer />
  </BrowserRouter>
);
