import React, {Fragment, useEffect, useState} from 'react';
import ContentHeaderPart from '../Helpers/contentHeaderPart';
import TitleAndDescription from '../Helpers/titleAndDescription';
import axios from "../../services/axios";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { ROOT_API } from '../../constants/index'
import {isBrowser} from "react-device-detect";
import SolariumMobile from "../Helpers/solariumMobile";
import MetaData from '../Helpers/meta_data';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseSvg from '../../images/close.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'
import {Link} from "react-router-dom";
import { createBrowserHistory } from 'history';


export default ({data, service_id})=> {
  const [headerData, setHeaderData] = useState({});
  const [visible, setVisible] = useState({isSecondOpen: true, isFirstOpen: true});
  const history = createBrowserHistory();


  const [open, setOpenModal] = React.useState(false);
  function handleClickOpen() {
    setOpenModal(true);
  }
  AOS.init({
    once: true,
  });
  function handleClose() {
    setOpenModal(false);
  }
  const tab = Number(window.location.pathname.split('tab/')[1] || Object.values(data.zagar_tabs)[0].id);
  const [tabContent, setTabContent] = useState(tab);

  if(!data || data === undefined) {
    const [data, setData] = useState({});
    useEffect(() => {
      axios().get('/submenu-page/1')
        .then(response => {
            setData(response.data);
            axios().get(`/submenu-headers/${data.submenu_headers_id}`)
              .then(response => setHeaderData(response.data))
          }
        )
        .catch(error => console.error(error));

        window.scrollTo(0, 0);
    }, []);
  } else {
    useEffect(() => {
      axios().get(`/submenu-headers/${data.submenu_headers_id}`)
        .then(response => setHeaderData(response.data))
        .catch(error => console.error(error));
        window.scrollTo(0, 0);

    }, []);
  }
  let images = [];
  for (let key in headerData) {
    if (key.indexOf('image') > -1) {
      images[key] = headerData[key];
    }
  }

  {data.meta_data.og_imagae =  !data.meta_data.og_image ? images.image_one  : data.meta_data.og_image}
  return(
    !data.zagar_tabs ? <div></div> :
    <div className="solarium container">
      <MetaData data={data.meta_data}/>
      {isBrowser ? 
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={CloseSvg} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
          : <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
      >
        <DialogTitle id="responsive-dialog-title">{"Для посещения солярия предварительная запись не требуется. Все дополнительные услуги проводятся в филиале на метро Фрунзенская."} 
        <div style={{float: 'right',marginTop: '-20px'}}>
          <IconButton aria-label="Close"  onClick={handleClose} className='modal-close'>
            <img src={CloseSvg} />
          </IconButton>
        </div>
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div >
            <iframe id="shedulWidget" className='book-now-modal' src="https://www.fresha.com/laguna-d0tuuyrz/booking"></iframe>
          </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>}
      <ContentHeaderPart title={headerData.title} text={headerData.text} images={images}/>
      <div className="menu-content-position">
        {
          isBrowser ?
            <Fragment>
              <div className="content-menu">
                {Object.values(data.zagar_tabs).map(item =>
                    <div className={`content-menu-items ${tabContent == item.id ? 'active-tab' : ''}`}
                         onClick={() => {
                           setTabContent(item.id);
                           history.push('/service/' + service_id + '/tab/'+item.id)

                           }}>{item.name}
                      <div className={`content-menu-address ${tabContent == item.id ? 'active-tab' : ''}`}
                           onClick={() => setTabContent(item.id)}>{item.address}</div>
                    </div>
                )}
              </div>
            </Fragment>

            :
            <div></div>
        }
        {isBrowser ?
          (Object.values(data.zagar_tabs).map(items =>
            tabContent === items.id &&
          items.zagar_blocks.map((item,key) =>
            <Fragment>
              { !!(key % 2 )?
        <div className="text-with-images">
          <div  className={`menu-text-content ${visible.isFirstOpen ? '' : ''}`}>
            <div className="title">{item.title}</div>
            <div className="context" dangerouslySetInnerHTML={{__html: (visible.isFirstOpen ? item.text.substring(0,375) : item.text) }}>
            </div>
            {item.text.length > 300 ?
              <div className="position see-details inner-see-details">
                <div onClick={()=>{
                  setVisible({isFirstOpen: !visible.isFirstOpen, isSecondOpen: visible.isSecondOpen})
                  if(!visible.isFirstOpen) window.scrollTo(0,0);
                }} className="floatRight top10">
                  {visible.isFirstOpen ? 'Читать далее' : 'Свернуть'}</div>
                <hr className="home-hr floatRight inner-hr"/>
              </div>
              : <div></div>
            }
            <div className="additional-info">
              <div class="price-div">
                <div>Цена:</div>
                <div className="cost">
                  <div dangerouslySetInnerHTML={{__html: item.price_text}}></div>
                </div>
              </div>
            </div>
            
          </div>
          <div className="menu-image-content" data-aos="fade-up">
            <div className="menu-image-item overlay zoom view"><img src={ROOT_API+'/uploads/'+item.image_one} alt=""/></div>
            <div className="menu-image-item overlay zoom view"><img src={ROOT_API+'/uploads/'+item.image_two} alt=""/></div>
          </div>
        </div>
        :
        <div className="text-with-images" data-aos="fade-up">
          <div className="menu-image-content floatLeft">
            <div className="menu-image-item overlay zoom view"><img src={ROOT_API+'/uploads/'+item.image_one} alt=""/></div>
            <div className="menu-image-item overlay zoom view"><img src={ROOT_API+'/uploads/'+item.image_two} alt=""/></div>
          </div>
          <div className={`menu-text-content floatRight ${visible.isSecondOpen ? '' : ''}`}>
            <div className="title">{item.title}</div>
            <div className="context" dangerouslySetInnerHTML={{__html: (visible.isSecondOpen ? item.text.substring(0,375) : item.text) }}>
            </div>
            {item.text.length > 300 ?
              <div className="position see-details inner-see-details">
                <div onClick={()=>{
                  setVisible({isSecondOpen: !visible.isSecondOpen,  isFirstOpen: visible.isFirstOpen})
                  if(!visible.isFirstOpen) window.scrollTo(0,0);
                }} className="floatRight top10">
                  {visible.isSecondOpen ? 'Читать далее' : 'Свернуть'}
                </div>
                <hr className="home-hr floatRight inner-hr"/>
              </div>
              : <div></div>
            }
            <div className="additional-info">
              <div class="price-div">
                <div>Цена:</div>
                <div className="cost">
                  <div dangerouslySetInnerHTML={{__html: item.price_text}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
              }
            </Fragment>
          )
        ))
          :
            <SolariumMobile data={data}/>
        }
        <Grid container className='collapse-grid' spacing={ 40 } justify="space-between">
          <Grid container justify="space-between" spacing={ 40 }>
            <Grid  item sm={12} xs={12} md={ 12 } lg={ 12 } width={ 100 }>
              <Paper className="{classes.paper}">
                <div className="tips-and-questions">
                  <div className="tips-and-questions__title">
                    {data.c_title}
                  </div>
                  <div className="tips-and-questions__context" dangerouslySetInnerHTML={{__html: data.c_content}}>
                  </div>
                    <div className="button-position">
                      <button className="tips-and-questions__button button-length" >
                      <Link to={'/contact'} >ПОСМОТРЕТЬ АДРЕСА</Link>

                        
                      </button>
                    </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <div className="four-title-and-description solarium-title-desc">
          {data.footer.map(item=>
            <TitleAndDescription title={item.title} description={item.text}/>
          )}
        </div>
      </div>
    </div>
  )
}
